/* Element Boundry Tool */

/* * {
  border: 1px solid red;
} */

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto+Slab:wght@300;400&family=Roboto:wght@300;400;500&display=swap');
@-ms-viewport{
  width: device-width;
}
/* Base Styling */
body,
html {
  margin: 0;
  padding: 0;
  background-color: #e5e5e5;
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  width: 100vw;
}

a {
  color: #303030;
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul li {
  list-style-type: none;
}

section a:hover {
  border-bottom: 1px solid #ff8f00;
}

form {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 32px;
  color: #fafafa;
  text-align: center;
}

input {
  width: 50%;
  display: block;
  margin: 15px auto;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  padding: .5rem;
}

button {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: rgb(51, 51, 51);
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .app {
  height: 100%;
} */

/* Nav Bar */

.header {
  position: fixed;
  width: 100%;
  background-color: #FAFAFA;
  top: 0;
  left: 0;
  height: 50px;
  z-index: 200;
  border-bottom: 1px solid #303030;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.nav-logo a {
color: #303030;
font-family: 'Bebas Neue', serif;
font-size: 40px;
height: 100%;
}

.nav a:hover, .nav a:active {
  color: rgba(73, 80, 87, 0.7);
  text-decoration: none;
}

.nav > ul {
  align-self: center;
  display: flex;
  justify-content: space-between;
  width: 79%;
  font-size: 20px;
  padding-left: 15.2rem;
}

.nav-login {
  display: inline-block;
  font-size: 20px;
  margin-left: 72px;
}

@media (max-width: 768px) {
  .nav > ul, .nav-login, .profile-icon {
    display: none;
  }
  .nav {
    display: flex;
    justify-content: flex-end;
    padding-left: 0.5rem;
    padding: 0;
    height: 100%;
  } 
  .nav-logo {
    
  }
  .hamburger {
    margin-left: 10px;
    margin-right: 10px;
    bottom: 2px;
  }
}

.profile-icon {
  font-size: 38px;
  margin-left: 311px;
}

.side-drawer {
  height: 100%;
  background-color: #FAFAFA;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
  0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  width: 60%;
  max-width: 400px;
  transform: translateX(100%);
  transition: transform 0.4s ease-out;
  z-index: 200;
  top: 50px;
}

.side-drawer.open {
  transform: translateX(0)
}

.side-drawer li {
  margin: 0.5rem 0;
  padding-left: 0.5rem;
  font-size: 1.2rem;
}

@media (min-width: 769px) {
  .side-drawer, .hamburger, .mobile-favorites-container, .mobile-trail-card {
    display: none;
  }
}

/* Main */

.background {
  width: 100vw;
  height: 100vh;
  background-color: black;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(./components/images/header.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  /* margin-bottom: 10vh; */
  /* position: absolute; */
}

.overlay {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: scroll;
}

.title {
  font-family: "Roboto", sans-serif;
  font-size: 3rem;
  font-weight: 500;
  color: #FAFAFA;
  /* max-width: 780px; */
  text-align: center;
  padding-top: 6vh;
  /* justify-content: right; */
  -webkit-animation: fadein 0.3s;
  -moz-animation: fadein 0.3s;
  -ms-animation: fadein 0.3s;
  -o-animation: fadein 0.3s;
  animation: fadein 0.3s;
}

/* Trails Card */
.trails-card {
  height: auto;
  width: 66%;
  padding: 30px 40px;
  background-color: #fafafa;
  margin: 0 auto 25px auto;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .trails-card {
    width: 77vw;
    padding: 20px 20px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
  }
}

.mobile-image-container {
  position: relative;
}

.trail-map {
  margin-top: 25px;
}

@media (max-width: 768px) {
  .trail-map {
    width: 100%;
    height: 33vh;
    margin-top: 0;
    /* position: absolute;
    bottom: 10px; */
  }
}

.trails-card a {
  color: #ff8f00;
  text-decoration: none;
}

/* #trail-reports-card > h2 {
  font-family: "Roboto Slab", serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.3);
  margin: 20px 0px;
} */

.trailcard-blurb {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 20px;
  /* width: 1280px */
}

@media (max-width: 768px) {
  .trailcard-blurb {
    font-size: 15px;
    margin: 0;
    margin-top: 10px;
  }
  /* .trails-card a {
    position: relative;
    left: 28px;
    bottom: 8px;
  } */
}

.legend-icons {
  position: relative;
  top: 7px;
  margin-right: 0.5rem;
}
#legend {
  list-style-type: none;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 32px;
  color: rgb(51, 51, 51);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  /* margin-bottom: 2em; */
}

#trails-table {
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  /* font-weight: bold; */
  line-height: 32px;
  color: rgb(51, 51, 51);
  justify-content: space-between;
  border-collapse: collapse;
}

@media (max-width: 768px) {
  #trails-table {
    display: none;
  }
}

th {
  padding: 1em;
  text-decoration: none;
  font-weight: 400;
}

tr {
  text-align: center;
  border-bottom: 1px solid;
  border-color: #cccccc;
}

td {
  padding-top: 10px;
  width: 10%;
  font-weight: 500;
}

.condition-details {
  width: 30%;
}

.mobile-card-header {
  display: flex;
}

.mobile-report {
  margin: 0;
  margin-top: 10px;
  text-align: center;
}

.mobile-report-date{
  text-align: center;
  margin: 0;
}

.mobile-trail-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.trail-name {
  width: 14%;
}

@media (max-width: 768px) {
  .trail-name {
    width: 100%;
    font-size: 16px;
    margin-top: 0;
  }
  .condition-details {
    width: auto;
    padding-left: 10px;
  }
  .mobile-status-symbol {
    position: absolute;
    bottom: -3px;
    right: 80px;
    display: inline;
    background: #fafafa;
    border-radius: 4px;
  }
  /* .condition-status {
    width: 25%
  } */
  /* .mobile-status-icon {
    text-align: center;
  } */
}

.trail-card {
  display: flex;
  height: 200px;
  width: 700px;
  /* padding: 30px 40px; */
  background-color: #fafafa;
  margin: 0 auto 60px auto;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  margin-top: 80px;
  /* Remember to change above prop once done testing */
  overflow: hidden;
}

.trail-img {
  width: 10vw;
  height: 15vh;
  object-fit: cover;
  cursor: pointer;
}

.trail-card-img {
  width: 50vw;
  margin: 0 auto;
  height: 20vh;
  object-fit: cover;
  display: block;
  border-radius: 3px;
}

.trails-card-summary {
  /* margin: 10px 0; */
  display: flex;
  /* justify-content: space-around; */
}
.trail-listing-action {
    cursor: pointer;
    margin: 10px auto;
    font-size: 1.5em;
}

@media (max-width: 768px) {
  .trail-listing-action {
  margin: 0;
  font-size: 24px;
  }
}

.trail-edit-icon {
  position: relative;
  left: 5px;
}

.trail-card-name {
  display: inline;
}
/* Modal Styling */

.modal-overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  min-width: 500px;
  width: auto;
  min-height: 290px;
  height: auto;
  background-color:#FAFAFA;
  border-radius: 6px;
  text-align: center;
  padding: 20px;
  padding-top: 40px;
  position: relative;
  outline: none;
}

.legend-modal {
  width: auto;
  height: auto;
  background-color:#FAFAFA;
  border-radius: 6px;
  padding: 20px;
  outline: none;
}

.update-modal {
  /* display: flex;
  flex-direction: column; */
}

.update-textarea {
  display: block;
  margin: 20px auto;
  font-family: "roboto", sans-serif;
  font-size: 18px;
  resize: none;
  overflow: auto;
}

.legend-link {
  cursor: pointer;
}

/* Login/Register Modal Styling */

.email-input {
  margin: 15px auto;
}

.login-button {
  padding: .5rem;
  width: 25%;
}

.login-register-link {
  color: #4C8BF5;
}

.close {
  position: absolute;
  top: 0;
  right: 14px;
  font-size: 42px;
  transform: rotate(45deg);
  cursor: pointer;
}

.login-form li,
.register-form li {
  color: black;
  margin-top: 10px;
  list-style: none;
}

#response-error-message {
  color: #DE5246;
}

.report-form > textarea {
  height: 50px;
  width: 260px;
  display: block;
  position: absolute;
  right: 135px;
}

.report-form > button {
  display: block;
  position: absolute;
  top: 267px;
  right: 245px;
}

.trail-preview-content {
  width: 1200px;
  height: 410px;
  padding: 40px 58px;
}

@media (max-width: 768px) {
  .trail-preview-content {
    width: 100vw;
    height: auto;
    min-width: 200px;
  }
}

.update-radio-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  position: relative;
  left: 20px;
}

.update-labels > p {
  margin-top: 0;
}

input[type="radio"] {
  transform: scale(1.5);
}

/* Home Styling */

.home-container {
  display: flex;
  width: 75vw;
  justify-content: center;
}

.trail-summary-card, .article-card, .weather-card {
  height: auto;
  padding: 10px 20px;
  background-color: #fafafa;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.trail-summary-card, .weather-card {
  width: 12vw;
}

.trail-summary-card {
padding-bottom: 20px;
}

.article-card {
width: 30vw;
margin: 0 1vw;
}

.trail-summary {
  display: flex;
  align-items: center;
}


/* Article Styling */

.article-title {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  margin-top: 10px;
}

.link {
  color: #ff8f00;
  /* text-decoration: none; */
  cursor: pointer;
}

address {
  font-weight: 300;
}

.news-image {
  max-width: 100%;
  margin-top: 1rem;
}

.news-container article:not(:first-child) {
  margin-top: 2rem;
}

/* .trail-summary {
  border-top: 1px solid #303030;
  border-bottom: 1px solid #303030;
} */

.trail-summary-card .trail-summary:not(:first-child) {
  border-top: 1px solid #303030;
}

.summary-symbol {
  font-size: 14px;
}

.summary-name {
  margin: 20px 0px;
  padding-left: 10px;
}

.summary-title {
  margin-bottom: 20px;
}

.weather-header {
  margin-bottom: 10px;
}

@media (max-width: 769px) {
  .trails-container, .weather-container {
    display: none;
  }
  .home-container {
    width: 100vw;
  }
  .article-card {
    width: 90vw;
  }
  .trails-card {
    width: 90vw;
  }
}